import styled,{keyframes} from "styled-components";


export const vem_2017 = keyframes`
        0% {
    right: 0%;
  }
  66.6666% {
    right: 50%;
    margin-right: -300px;
  }
  90% {
    right: 50%;
    margin-right: -300px;
  }
  100% {
    right: 50%;
  }
`

export const vem_e_vai_balao = keyframes`
        0% {
    right: 0%;
  }
  40% {
    right: 50%;
    margin-right: -300px;
  }
  50% {
    right: 50%;
    margin-right: -200px;
    top: 50%;
  }
  100% {
    top: -100%;
    right: 50%;
  }
`

export const entrega_balao = keyframes`
          0% {
    transform: rotate(-30deg);
    width: 40px;
  }
  100% {
    transform: rotate(-150deg);
    width: 70px;
  }
`

export const vai_2016 = keyframes`
        0% {
    top: 50%;
  }
  100% {
    top: -100%;
  }
`

export const vem_feliz = keyframes`
        0% {
    margin-top: 0px;
    opacity: 0;
  }
  100% {
    margin-top: -200px;;
    opacity: 1;
  }
`

export const solta_fogos = keyframes`
         0% {
    margin-top: 100%;
    opacity: 0;
    width: 2px;
    height: 30px;
    display: block;
    border-radius: 50%;
  }
  75% {
    margin-top: 0%;
    opacity: 1;
    width: 2px;
    height: 30px;
    display: block;
    border-radius: 50%;
  }
  80%{
    margin-top: 0px;
    margin-left: 0px;
    opacity: 1;
    width: 10px;
    height: 10px;
    display: block;
    border-radius: 50%;
    transform: scale(0.2);
  }
  100%{
    opacity: 1;
    width: 10px;
    height: 10px;
    display: block;
    border-radius: 50%;
    transform: scale(1);
  }
`

export const estoura_fogos = keyframes`
        0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const Main = styled.div`
overflow:hidden;
        min-height:100vh;
        min-width:100vw;
        position:absolute;
        top:0;
        left:0;
        z-index:999999999;
        background: #222048;
        box-sizing:border-box;
        margin:0;
        padding:0;
        -webkit-transition: all 100ms ease-in;
        transition: all 100ms ease-in;
      &:after{
        box-sizing:border-box;
        margin:0;
        padding:0;
        -webkit-transition: all 100ms ease-in;
        transition: all 100ms ease-in;
      }
      &:before{
        box-sizing:border-box;
        margin:0;
        padding:0;
        -webkit-transition: all 100ms ease-in;
        transition: all 100ms ease-in;
      }
`
export const textclip = keyframes`
   from {
    background-position: 200% center;
  }
`

export const Feliz = styled.div`
        width: 100%;
        font-family: 'Pacifico', cursive;
        font-size: 100px;
        font-weight: 700;
        color: #f48fb1;
        text-align: center;
        position: absolute;
        top: 50%;
        opacity: 0;
        animation:  ${vem_feliz} 2s ease-in-out 7s forwards;

        text-transform: uppercase;
         background-image: linear-gradient(
                -225deg,
                #231557 0%,
                #44107a 29%,
                #ff1361 67%,
                #fff800 100%
            );  
            background-size: auto auto;
            background-clip: border-box;
            background-size: 200% auto;
            background-clip: text;
            text-fill-color: transparent;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            animation: ${textclip} 10s linear infinite;
            display: inline-block;
               




`

export const Ano_novo = styled.div`
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            padding: 200px 100px 0px 0px;
`

export const Ano_novo_span = styled.span`
    font-family: 'Montserrat', sans-serif;
    font-size: 175px;
    font-weight: 700;
    color: #7a8fe8;
`

export const Ano_novo_span_seis = styled.span`
font-family: 'Montserrat', sans-serif;
    font-size: 175px;
    font-weight: 700;
    color: #7a8fe8;
    position: absolute;
  top: 50%;
  right: 50%;
  margin-right: -200px;
  animation: ${vai_2016} 5s ease-in-out 5s forwards;
`

export const Ano_novo_span_sete  = styled.span`
    font-family: 'Montserrat', sans-serif;
    font-size: 175px;
    font-weight: 700;
    color: #7a8fe8;
        position: absolute;
        right: 0%;
        margin-right: -200px;
        animation: ${vem_2017} 6s ease-in-out forwards;
        &:before{
            content: '';
            width: 0px;
            height: 6px;
            display: block;
            border-radius: 3px;
            background: #7a8fe8;
            transform: rotate(0deg);
            transform-origin: left top;
            position: absolute;
            top: 55px;
            left: 10px;
            z-index: -1;
            animation: ${entrega_balao} 1s ease-in-out 4s;
        }
  `

export const Balao = styled.div`
        width: 100px;
        height: 100px;
        display: block;
        background: #e8d57a;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        margin-top: -165px;
        right: 0%;
        margin-right: -200px;
        animation: ${vem_e_vai_balao} 10s ease-in-out forwards;
        &:before{
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 10px 20px 10px;
            border-color: transparent transparent #b19b32 transparent;
            position: absolute;
            left: 50%;
            margin-left: -10px;
            bottom: -10px;
            z-index: -1;
        }
        &:after{
            content: '';
            width: 4px;
            height: 100px;
            display: block;
            background: #fff;
            border-radius: 0px 0px 3px 3px;
            position: absolute;
            left: 50%;
            margin-left: -2px;
            bottom: -110px;
        }
`

export const Fogos = styled.div`
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
`

export const FogosF1 = styled.div`
  border: 2px solid #fff;
  position: absolute;
  opacity: 0;
  animation: ${solta_fogos} 15s ease-in-out 8s forwards infinite;
  left: 20%;
  top: 40%;
`

export const FogosF2 = styled.div`
     border: 2px solid #fff;
  position: absolute;
  opacity: 0;
  animation: ${solta_fogos} 15s ease-in-out 8s forwards infinite;
  left: 15%;
  top: 70%;
`

export const FogosF3 = styled.div`
     border: 2px solid #fff;
  position: absolute;
  opacity: 0;
  animation: ${solta_fogos} 15s ease-in-out 8s forwards infinite;
  right: 20%;
  top: 40%;
`
export const FogosF4 = styled.div`
     border: 2px solid #fff;
  position: absolute;
  opacity: 0;
  animation: ${solta_fogos} 15s ease-in-out 8s forwards infinite;
  right: 15%;
  top: 70%;
`

export const DivSpan1 =styled.span`
  width: 6px;
  height: 6px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  animation: ${estoura_fogos} 15s ease-in-out 9s forwards infinite;
  transform: rotate(0deg);
  &:before{
    content: '';
    width: 2px;
    height: 50px;
    display: block;
    background: #f5cc06;
    position: absolute;
    top: -60px;
    left: 2px;
  }
  &:after{
    content: '';
    width: 2px;
    height: 50px;
    display: block;
    background: #f5cc06;
    position: absolute;
    bottom: -60px;
    left: 2px;
  }
`

export const DivSpan2 =styled.span`
  width: 6px;
  height: 6px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  animation: ${estoura_fogos} 15s ease-in-out 9s forwards infinite;
  transform: rotate(120deg);
  &:before{
    content: '';
    width: 2px;
    height: 50px;
    display: block;
    background: #f5cc06;
    position: absolute;
    top: -60px;
    left: 2px;
  }
  &:after{
    content: '';
    width: 2px;
    height: 50px;
    display: block;
    background: #f5cc06;
    position: absolute;
    bottom: -60px;
    left: 2px;
  }
`

export const DivSpan3 =styled.span`
  width: 6px;
  height: 6px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  animation: ${estoura_fogos} 15s ease-in-out 9s forwards infinite;
  transform: rotate(240deg);
  &:before{
    content: '';
    width: 2px;
    height: 50px;
    display: block;
    background: #f5cc06;
    position: absolute;
    top: -60px;
    left: 2px;
  }
  &:after{
    content: '';
    width: 2px;
    height: 50px;
    display: block;
    background: #f5cc06;
    position: absolute;
    bottom: -60px;
    left: 2px;
  }
`

export const DivSpanI = styled.i`
        &:before{
            content: '';
            width: 3px;
            height: 3px;
            display: block;
            border-radius: 50%;
            background: #fff;
            position: absolute;
            top: -15px;
            left: 10px;
        }
        &:after{
            content: '';
            width: 3px;
            height: 3px;
            display: block;
            border-radius: 50%;
            background: #fff;
            position: absolute;
            top: -15px;
            right: 10px;
        }
`





