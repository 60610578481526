export const OPEN_MENU_BAR_QUAN_TRI  = "OPEN_MENU_BAR_QUAN_TRI"
export const CLOSE_MENU_BAR_QUAN_TRI = "CLOSE_MENU_BAR_QUAN_TRI"

export const OPEN_MENU_BAR_CONG_VIEC  = "OPEN_MENU_BAR_CONG_VIEC"
export const CLOSE_MENU_BAR_CONG_VIEC = "CLOSE_MENU_BAR_CONG_VIEC"



export const CREATE_INFO_GROUP_QUAN_TRI = "CREATE_INFO_GROUP_QUAN_TRI"
export const DISPATCH_ITEM_INFO_GROUP_TO_STORE = "DISPATCH_ITEM_INFO_GROUP_TO_STORE"

export const ADD_INFO_GROUP_QUAN_TRI        = "ADD_INFO_GROUP_QUAN_TRI"
export const DISPATCH_ADD_BUSSINES_QUAN_TRI = "DISPATCH_ADD_BUSSINES_QUAN_TRI"

export const ADD_UPDATE_QUAN_TRI_SUCCESS  = "ADD_UPDATE_QUAN_TRI_SUCCESS"
export const ADD_UPDATE_QUAN_TRI_FAIL     = "ADD_UPDATE_QUAN_TRI_FAIL"

export const UPDATE_BUSSINES_QUAN_TRI = "UPDATE_BUSSINES_QUAN_TRI"
export const DELETE_ITEM_BUSSINES_QUAN_TRI = "DELETE_ITEM_BUSSINES_QUAN_TRI"
export const SELECT_ITEM_BUSSINES_QUAN_TRI = "SELECT_ITEM_BUSSINES_QUAN_TRI"

//NHA CUNG CAP
export const ADD_ITEM_SUPPIES_QUAN_TRI            = "ADD_ITEM_SUPPIES_QUAN_TRI"
export const ADD_ITEM_SUPPIES_QUAN_TRI_SUCCESS    = "ADD_ITEM_SUPPIES_QUAN_TRI_SUCCESS"
export const UPDATE_ITEM_SUPPIES_QUAN_TRI         = "UPDATE_ITEM_SUPPIES_QUAN_TRI"
export const DELETE_ITEM_SUPPIES_QUAN_TRI         = "DELETE_ITEM_SUPPIES_QUAN_TRI"

export const ADD_ITEM_CUS_QUAN_TRI = "ADD_ITEM_CUS_QUAN_TRI"
export const ADD_ITEM_CUS_QUAN_TRI_SUCCESS = "ADD_ITEM_CUS_QUAN_TRI_SUCCESS"
export const UPDATE_ITEM_CUS_QUAN_TRI      = "UPDATE_ITEM_CUS_QUAN_TRI"

export const DELETE_ITEM_CUS_QUAN_TRI = "DELETE_ITEM_CUS_QUAN_TRI"

export const ADD_ITEM_STAFF_QUAN_TRI = "ADD_ITEM_STAFF_QUAN_TRI"
export const ADD_ITEM_STAFF_QUAN_TRI_SUCCESS = "ADD_ITEM_STAFF_QUAN_TRI_SUCCESS"
export const DELETE_ITEM_STAFF_QUAN_TRI           = "DELETE_ITEM_STAFF_QUAN_TRI"

export const UPDATE_ITEM_STAFF_QUAN_TRI      = "UPDATE_ITEM_STAFF_QUAN_TRI"

export const ADD_ITEM_DOI_TAC_QUAN_TRI              = "ADD_ITEM_DOI_TAC_QUAN_TRI"
export const ADD_ITEM_DOI_TAC_QUAN_TRI_SUCCESS      = "ADD_ITEM_DOI_TAC_QUAN_TRI_SUCCESS"
export const UPDATE_ITEM_DOI_TAC_QUAN_TRI           = "UPDATE_ITEM_DOI_TAC_QUAN_TRI"
export const DELETE_ITEM_DOI_TAC_QUAN_TRI           = "DELETE_ITEM_DOI_TAC_QUAN_TRI"


//PARMENT PHONG BAN
export const ADD_PARMENT_QUAN_TRI = "ADD_PARMENT_QUAN_TRI"
export const ADD_PARTMENT_QUAN_TRI_SUCCESS = "ADD_PARTMENT_QUAN_TRI_SUCCESS"
export const UPDATE_PARTMENT_QUAN_TRI      = "UPDATE_PARTMENT_QUAN_TRI"
export const DELETE_PARTMENT_QUAN_TRI      = "DELETE_PARTMENT_QUAN_TRI"

//JOB
export const ADD_JOB_QUAN_TRI = "ADD_JOB_QUAN_TRI"
export const ADD_JOB_QUAN_TRI_SUCCESS = "ADD_JOB_QUAN_TRI_SUCCESS"
export const UPDATE_JOB_QUAN_TRI      = "UPDATE_JOB_QUAN_TRI"
export const DELETE_JOB_QUAN_TRI      = "DELETE_JOB_QUAN_TRI"

//CATE DANH MUC HANG
export const ADD_CATE_QUAN_TRI         = "ADD_CATE_QUAN_TRI"
export const ADD_CATE_QUAN_TRI_SUCCESS = "ADD_CATE_QUAN_TRI_SUCCESS"
export const DELETE_CATE_QUAN_TRI = "DELETE_CATE_QUAN_TRI"
export const UPDATE_CATE_QUAN_TRI = "UPDATE_CATE_QUAN_TRI"
//PRODUCT
 export const DISPATCH_ITEM_CATE_QUAN_TRI_TO_STORE       = "DISPATCH_ITEM_CATE_QUAN_TRI_TO_STORE"
 export const DISPATCH_ITEM_CATE_QUAN_TRI_TO_STORE_AGAIN = "DISPATCH_ITEM_CATE_QUAN_TRI_TO_STORE_AGAIN"

 export const ADD_PRODUCT_QUAN_TRI  ="ADD_PRODUCT_QUAN_TRI"
 export const ADD_PRODUCT_QUAN_TRI_SUCCESS ="ADD_PRODUCT_QUAN_TRI_SUCCESS"
 export const DELETE_PRODUCT_QUAN_TRI ="DELETE_PRODUCT_QUAN_TRI"
 export const UPDATE_PRODUCT_QUAN_TRI ="UPDATE_PRODUCT_QUAN_TRI"


//LOADING DATA
export const LOADING_DATA = "LOADING_DATA"

export const CREATE_INFO_CUS_LOGIN_LIST_SUPPLIES         = "CREATE_INFO_CUS_LOGIN_LIST_SUPPLIES"
export const DISPATCH_CREATE_INFO_CUS_STORE              = "DISPATCH_CREATE_INFO_CUS_STORE"
export const GET_INFO_CUS_LOGIN_LIST_SUPPLIES            = "GET_INFO_CUS_LOGIN_LIST_SUPPLIES"
export const DISPATCH_INFO_CUS_LOGIN_LIST_SUPPLIES_STORE = "DISPATCH_INFO_CUS_LOGIN_LIST_SUPPLIES_STORE"

export const GET_INFO_CUS_LOGIN_LIST_CUS                = "GET_INFO_CUS_LOGIN_LIST_CUS"

export const DEACTIVE_ACCOUNT_INFO_CUS_LOGIN = "DEACTIVE_ACCOUNT_INFO_CUS_LOGIN"
export const ACTIVE_ACCOUNT_INFO_CUS_LOGIN   = "ACTIVE_ACCOUNT_INFO_CUS_LOGIN"

export const DISPATCH_UPDATE_ACTIVE_ACCOUNT_INFO_CUS_LOGIN = "DISPATCH_UPDATE_ACTIVE_ACCOUNT_INFO_CUS_LOGIN"
export const DISPATCH_ACTIVE_ACCOUNT_INFO_CUS_LOGIN        = "DISPATCH_ACTIVE_ACCOUNT_INFO_CUS_LOGIN"

export const DISPATCH_INFO_CUS_LOGIN_LIST_CUS_STORE = "DISPATCH_INFO_CUS_LOGIN_LIST_CUS_STORE"

export const CREATE_INFO_CUS_LOGIN_LIST_CUS = "CREATE_INFO_CUS_LOGIN_LIST_CUS"
export const DISPATCH_CREATE_INFO_LIST_CUS_STORE = "DISPATCH_CREATE_INFO_LIST_CUS_STORE"
export const DEACTIVE_ACCOUNT_INFO_LIST_CUS_LOGIN = "DEACTIVE_ACCOUNT_INFO_LIST_CUS_LOGIN"
export const ACTIVE_ACCOUNT_INFO_LIST_CUS_LOGIN   = "ACTIVE_ACCOUNT_INFO_LIST_CUS_LOGIN"
export const DISPATCH_UPDATE_ACTIVE_ACCOUNT_INFO_LIST_CUS_LOGIN = "DISPATCH_UPDATE_ACTIVE_ACCOUNT_INFO_LIST_CUS_LOGIN"
export const DISPATCH_ACTIVE_ACCOUNT_INFO_LIST_CUS_LOGIN        = "DISPATCH_ACTIVE_ACCOUNT_INFO_LIST_CUS_LOGIN"

//STAFF

export const CREATE_INFO_CUS_LOGIN_LIST_STAFF      = "CREATE_INFO_CUS_LOGIN_LIST_STAFF"
export const DISPATCH_CREATE_INFO_LIST_STAFF_STORE = "DISPATCH_CREATE_INFO_LIST_STAFF_STORE"
export const DEACTIVE_ACCOUNT_INFO_LIST_STAFF_LOGIN = "DEACTIVE_ACCOUNT_INFO_LIST_STAFF_LOGIN"
export const ACTIVE_ACCOUNT_INFO_LIST_STAFF_LOGIN   = "ACTIVE_ACCOUNT_INFO_LIST_STAFF_LOGIN"
export const DISPATCH_UPDATE_ACTIVE_ACCOUNT_INFO_LIST_STAFF_LOGIN = "DISPATCH_UPDATE_ACTIVE_ACCOUNT_INFO_LIST_STAFF_LOGIN"
export const DISPATCH_ACTIVE_ACCOUNT_INFO_LIST_STAFF_LOGIN        = "DISPATCH_ACTIVE_ACCOUNT_INFO_LIST_STAFF_LOGIN"
export const DISPATCH_INFO_CUS_LOGIN_LIST_STAFF_STORE = "DISPATCH_INFO_CUS_LOGIN_LIST_STAFF_STORE"
export const GET_INFO_CUS_LOGIN_LIST_STAFF                = "GET_INFO_CUS_LOGIN_LIST_STAFF"

//DOI TAC
export const CREATE_INFO_CUS_LOGIN_LIST_DOI_TAC      = "CREATE_INFO_CUS_LOGIN_LIST_DOI_TAC"
export const DISPATCH_CREATE_INFO_LIST_DOI_TAC_STORE = "DISPATCH_CREATE_INFO_LIST_DOI_TAC_STORE"
export const DEACTIVE_ACCOUNT_INFO_LIST_DOI_TAC_LOGIN = "DEACTIVE_ACCOUNT_INFO_LIST_DOI_TAC_LOGIN"
export const ACTIVE_ACCOUNT_INFO_LIST_DOI_TAC_LOGIN   = "ACTIVE_ACCOUNT_INFO_LIST_DOI_TAC_LOGIN"
export const DISPATCH_UPDATE_ACTIVE_ACCOUNT_INFO_LIST_DOI_TAC_LOGIN = "DISPATCH_UPDATE_ACTIVE_ACCOUNT_INFO_LIST_DOI_TAC_LOGIN"
export const DISPATCH_ACTIVE_ACCOUNT_INFO_LIST_DOI_TAC_LOGIN        = "DISPATCH_ACTIVE_ACCOUNT_INFO_LIST_DOI_TAC_LOGIN"
export const DISPATCH_INFO_CUS_LOGIN_LIST_DOI_TAC_STORE = "DISPATCH_INFO_CUS_LOGIN_LIST_DOI_TAC_STORE"
export const GET_INFO_CUS_LOGIN_LIST_DOI_TAC                = "GET_INFO_CUS_LOGIN_LIST_DOI_TAC"

//DETAIL_PRODUCT_QUAN_TRI
export const ADD_DETAIL_PRODUCT_QUAN_TRI          = "ADD_DETAIL_PRODUCT_QUAN_TRI"
export const DISPATCH_ADD_DETAIL_PRODUCT_QUAN_TRI_STORE = "DISPATCH_ADD_DETAIL_PRODUCT_QUAN_TRI_STORE"
export const GET_DETAIL_PRODUCT_QUAN_TRI          = "GET_DETAIL_PRODUCT_QUAN_TRI"
export const DISPATCH_GET_DETAIL_PRODUCT_QUAN_TRI = "DISPATCH_GET_DETAIL_PRODUCT_QUAN_TRI"
export const UPDATE_DETAIL_PRODUCT_QUAN_TRI       = "UPDATE_DETAIL_PRODUCT_QUAN_TRI"
export const CHANGE_HIDE_DETAIL_PRODUCT_QUAN_TRI  = "CHANGE_HIDE_DETAIL_PRODUCT_QUAN_TRI"
export const CHANGE_SHOW_DETAIL_PRODUCT_QUAN_TRI  = "CHANGE_SHOW_DETAIL_PRODUCT_QUAN_TRI"
export const DELETE_DETAIL_PRODUCT_QUAN_TRI       = "DELETE_DETAIL_PRODUCT_QUAN_TRI"
export const UPDATE_AVATAR_DETAIL_PRODUCT_QUAN_TRI ="UPDATE_AVATAR_DETAIL_PRODUCT_QUAN_TRI"
//ID_LOGIN_INFO_CUS
export const GET_ID_LOGIN_INFO_CUS = "GET_ID_LOGIN_INFO_CUS"
export const DISPATCH_GET_ID_LOGIN_INFO_CUS = "DISPATCH_GET_ID_LOGIN_INFO_CUS"
export const DISPATCH_INFO_SEARCH_TO_STORE  = "DISPATCH_INFO_SEARCH_TO_STORE"
//GET_LIST_PRODUCT_BY_ID_DETAIL_PRODUCT 
export const GET_LIST_PRODUCT_BY_ID_DETAIL_PRODUCT="GET_LIST_PRODUCT_BY_ID_DETAIL_PRODUCT"
//GET_LÍST_JOB_BY_CUS
export const GET_LIST_JOB_QUAN_TRI_BY_CUS = "GET_LIST_JOB_QUAN_TRI_BY_CUS"
export const DISPATCH_LIST_JOB_QUAN_TRI_BY_CUS        = "DISPATCH_LIST_JOB_QUAN_TRI_BY_CUS"
export const DISPATCH_UPDATE_LIST_JOB_QUAN_TRI_BY_CUS = "DISPATCH_UPDATE_LIST_JOB_QUAN_TRI_BY_CUS"
export const DISPATCH_ALL_UPDATE_LIST_JOB_QUAN_TRI_BY_CUS = "DISPATCH_ALL_UPDATE_LIST_JOB_QUAN_TRI_BY_CUS"
// export const DISPATCH_UN_SELECT_ALL_UPDATE_LIST_JOB_QUAN_TRI_BY_CUS = "DISPATCH_UN_SELECT_ALL_UPDATE_LIST_JOB_QUAN_TRI_BY_CUS"
export const OPEN_FORM_BAO_GIA_CUS_JOB_STAFF  = "OPEN_FORM_BAO_GIA_CUS_JOB_STAFF"
export const CLOSE_FORM_BAO_GIA_CUS_JOB_STAFF = "CLOSE_FORM_BAO_GIA_CUS_JOB_STAFF"
//SELECT_UN_SEELCT_BAO_GIA_CUS_JOB_STAFF 
export const SELECT_BAO_GIA_CUS_JOB_STAFF    = "SELECT_BAO_GIA_CUS_JOB_STAFF"
export const UN_SELECT_BAO_GIA_CUS_JOB_STAFF = "UN_SELECT_BAO_GIA_CUS_JOB_STAFF"
export const DELETE_ITEM_BAO_GIA_CUS_JOB_STAFF = "DELETE_ITEM_BAO_GIA_CUS_JOB_STAFF"

export const SELECT_ALL_BAO_GIA_CUS_JOB_STAFF       = "SELECT_ALL_BAO_GIA_CUS_JOB_STAFF"
export const UN_SELECT_ALL_BAO_GIA_CUS_JOB_STAFF    = "UN_SELECT_ALL_BAO_GIA_CUS_JOB_STAFF"


export const CREATE_BAO_GIA_CUS_JOB_STAFF = "CREATE_BAO_GIA_CUS_JOB_STAFF"
export const CREATE_BAO_GIA_CUS_JOB_STAFF_SUCCESS = "CREATE_BAO_GIA_CUS_JOB_STAFF_SUCCESS"

export const SEARCH_BAO_GIA_CUS_JOB_STAFF = "SEARCH_BAO_GIA_CUS_JOB_STAFF"
export const DISPATCH_SEARCH_BAO_GIA_CUS_JOB_STAFF = "DISPATCH_SEARCH_BAO_GIA_CUS_JOB_STAFF"

export const UPDATE_BAO_GIA_JOB_STAFF                = "UPDATE_BAO_GIA_JOB_STAFF"
export const DISPATCH_ITEM_LIST_PRODUCT_CUS_TO_STORE          = "DISPATCH_ITEM_LIST_PRODUCT_CUS_TO_STORE"
export const DISPATCH_ITEM_LIST_PRODUCT_CUS_TO_STORE_PHAN_MEM = "DISPATCH_ITEM_LIST_PRODUCT_CUS_TO_STORE_PHAN_MEM"
export const EDIT_ITEM_LIST_PRODUCT_CUS_JOB_STAFF = "EDIT_ITEM_LIST_PRODUCT_CUS_JOB_STAFF"
export const DELETE_ITEM_LIST_PRODUCT_CUS_JOB_STAFF    = "DELETE_ITEM_LIST_PRODUCT_CUS_JOB_STAFF"
export const DELETE_ITEM_ROW_LIST_PRODUCT_CUS_JOB_STAFF = "DELETE_ITEM_ROW_LIST_PRODUCT_CUS_JOB_STAFF"

export const OPEN_ADD_PRODUCT_IN_LIST_PRODUCT_CUS_JOB_STAFF  = "OPEN_ADD_PRODUCT_IN_LIST_PRODUCT_CUS_JOB_STAFF"
export const CLOSE_ADD_PRODUCT_IN_LIST_PRODUCT_CUS_JOB_STAFF = "CLOSE_ADD_PRODUCT_IN_LIST_PRODUCT_CUS_JOB_STAFF"

export const OPEN_ADD_BAO_GIA_IN_LIST_PRODUCT_CUS_JOB_STAFF  = "OPEN_ADD_BAO_GIA_IN_LIST_PRODUCT_CUS_JOB_STAFF"
export const CLOSE_ADD_BAO_GIA_IN_LIST_PRODUCT_CUS_JOB_STAFF = "CLOSE_ADD_BAO_GIA_IN_LIST_PRODUCT_CUS_JOB_STAFF"
export const EDIT_ADD_ITEM_LIST_PRODUCT_CUS_JOB_STAFF = "EDIT_ADD_ITEM_LIST_PRODUCT_CUS_JOB_STAFF"
export const GET_EDIT_ADD_ITEM_LIST_PRODUCT_CUS_JOB_STAFF = "GET_EDIT_ADD_ITEM_LIST_PRODUCT_CUS_JOB_STAFF"

export const OPEN_TAI_KHOAN_CHINH  = "OPEN_TAI_KHOAN_CHINH"
export const CLOSE_TAI_KHOAN_CHINH = "CLOSE_TAI_KHOAN_CHINH"

export const UPDATE_LOGIN_ACCOUNT_QUAN_TRI = "UPDATE_LOGIN_ACCOUNT_QUAN_TRI"
export const GET_NEW_UPDATE_LOGIN_ACCOUNT_QUAN_TRI = "GET_NEW_UPDATE_LOGIN_ACCOUNT_QUAN_TRI"
export const UPDATE_ACCOUNT_INFO_QUAN_TRI = "UPDATE_ACCOUNT_INFO_QUAN_TRI"
export const UPDATE_ACCOUNT_INFO_QUAN_TRI_AGAIN = "UPDATE_ACCOUNT_INFO_QUAN_TRI_AGAIN"
export const DISPATCH_UPDATE_ACCOUNT_INFO_QUAN_TRI = "DISPATCH_UPDATE_ACCOUNT_INFO_QUAN_TRI"
export const DISPATCH_ITEM_CUS_INFO_TO_STORE = "DISPATCH_ITEM_CUS_INFO_TO_STORE"

export const OPEN_TEMPLETE_BAO_GIA = "OPEN_TEMPLETE_BAO_GIA"
export const CLOSE_TEMPLETE_BAO_GIA = "CLOSE_TEMPLETE_BAO_GIA"

export const OPEN_TEMPLETE_HOP_DONG = "OPEN_TEMPLETE_HOP_DONG"
export const CLOSE_TEMPLETE_HOP_DONG = "CLOSE_TEMPLETE_HOP_DONG"
export const DISPATCH_ACCOUNT_BANK_TO_STORE = "DISPATCH_ACCOUNT_BANK_TO_STORE"

export const CREATE_OR_GET_GIAM_DINH_NGHIEM_THU = "CREATE_OR_GET_GIAM_DINH_NGHIEM_THU"
export const DISPATCH_GIAM_DINH_NGHIEM_THU_TO_STORE = "DISPATCH_GIAM_DINH_NGHIEM_THU_TO_STORE"
export const UPDATE_GIAM_DINH_NGHIEM_THU_TO_STORE = "UPDATE_GIAM_DINH_NGHIEM_THU_TO_STORE"


export const OPEN_ADD_LIST_NOT_PRODUCT_CUS_JOB_STAFF  = "OPEN_ADD_LIST_NOT_PRODUCT_CUS_JOB_STAFF"
export const CLOSE_ADD_LIST_NOT_PRODUCT_CUS_JOB_STAFF = "CLOSE_ADD_LIST_NOT_PRODUCT_CUS_JOB_STAFF"
export const COMPLETE_HOP_DONG = "COMPLETE_HOP_DONG"
export const UN_COMPLETE_HOP_DONG = "UN_COMPLETE_HOP_DONG"

export const DISPATCH_UPDATE_ITEM_LIST_JOB_BY_CUS = "DISPATCH_UPDATE_ITEM_LIST_JOB_BY_CUS"
export const DISPATCH_VALUE_GIAM_DINH_TO_STORE    = "DISPATCH_VALUE_GIAM_DINH_TO_STORE"

export const CREATE_BAO_GIA_PHAN_MEM            = "CREATE_BAO_GIA_PHAN_MEM"
export const DISPATCH_LIST_SOFT_KIDTOP_TO_STORE = "DISPATCH_LIST_SOFT_KIDTOP_TO_STORE"

